import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import { demoTestimonials } from "../../data/restaurant-data";
import { terminalTrustedNumbers } from "../../data/terminal-landing-data";
// import LpFormShort from "../../components/ConversionPath/LpFormShort";
// import Image from "../../components/image";
// import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
// import TrustedNumnbers from "../../components/TrustedNumbers/TrustedNumbers";
// import PartnerLogos from "../../components/PartnerLogos/PartnerLogos";
import ogImage from "../../images/global_assets/og-image.png";
import data from "../../data/forms/lead-form";
import { extractDefaultValues } from "../../utils/form-utils";
import { lineOfBusiness } from "../../constants";
import LeadForm from "../../components/Forms/LeadForm";

const LpFormShort = loadable(() =>
  import("../../components/ConversionPath/LpFormShort")
);
const Image = loadable(() => import("../../components/image"));
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const TrustedNumnbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const PartnerLogos = loadable(() =>
  import("../../components/PartnerLogos/PartnerLogos")
);

export default function AutoDemoPageLp() {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="Learn more | SpotOn"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="form-wrapper">
            <LeadForm
              formName="Lead Form"
              displayForm
              formData={data}
              initialValues={extractDefaultValues(data)}
              header="Get your free demo"
              subHeader="Attract more customers, get paid, and keep customers coming back with a toolset that’s built for your business."
              onValuesChange={() => true}
              useFormValueAsDataLayerEventName
              formValueForDataLayerEventName="industry"
              dataLayerEventNameAppendix="-lead"
              withLineOfBusinessOption={false}
              lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
              thankYouPath="/lp/thank-you"
              leadType="lead-gen"
            />
          </div>
        </div>
        <Image
          imageName="auto-demo-img.png"
          className="demo__heading-img auto"
        />
        <section style={{ marginTop: 120 }}>
          {/* <Badges /> */}
          <TrustedNumnbers numbersArray={terminalTrustedNumbers} />
        </section>
        <section style={{ marginTop: 60 }}>
          <TestmonialReviews sectionData={demoTestimonials} />
        </section>
      </section>
      <section style={{ marginBottom: 40 }}>
        <PartnerLogos />
      </section>
    </LpLayout>
  );
}
